<template>
  <v-dialog v-model="dialog" max-width="500">
    <template #activator="{ props: dialogProps }">
      <v-tooltip location="bottom">
        <template #activator="{ props: tooltipProps }">
          <v-btn :disabled="isLoading" v-bind="{ ...dialogProps, ...tooltipProps }" class="secondary-button-mini">
            Fravælg
          </v-btn>
        </template>
        <span>Hvis servicen ikke lever op til dine krav kan du fravælge den. Det er altid muligt at tilvælge servicen igen.</span>
      </v-tooltip>
    </template>
    <v-card class="pa-4">
      <v-card-title class="text-primary pl-0 pt-0">
        Fravælg service
      </v-card-title>
      <v-textarea
        v-model="note"
        no-resize
        type="text"
        variant="outlined"
        placeholder="Du bør indsætte en begrundelse for dit fravalg. Fravalget skal være sagligt begrundet ifht. din behovsopgørelse."
        density="compact"
        rows="2"
      >
      </v-textarea>
      <div class="mt-2 d-flex justify-center">
        <v-btn
          class="secondary-button-mini mr-3"
          @click="closeDialog()"
          data-cy="cancel-tender-cancel"
        >
          Annullér
        </v-btn>
        <v-btn
          @click="saveNote()"
          :disabled="!note"
          class="primary-button-mini"
        >Fravælg</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const emit = defineEmits<{ reject: [string] }>();
const { isLoading = false } = defineProps<{ isLoading?: boolean }>();
const dialog = ref(false);
const note = ref('');

function closeDialog() {
  dialog.value = !dialog.value;
}

function saveNote() {
  emit('reject', note.value);
  closeDialog();
}

</script>

<style scoped></style>
