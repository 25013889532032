<template>
  <v-card variant="flat" class="bg-white">
    <v-card-text>
      <VuePdfEmbed :source="getPdf()" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        class="primary-button"
        @click="downloadArtifact()"
      >
        Download
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">

import { downloadDocument, DownloadDocument } from '@dims/components';
import VuePdfEmbed from 'vue-pdf-embed';

const { document } = defineProps<{ document?: DownloadDocument }>();

function getPdf() {
  return `data:application/pdf;base64,${document?.fileData}`;
}

function downloadArtifact() {
  if (document) {
    downloadDocument(document);
  }
}

</script>

<style scoped>

.small-table {
  width: 50%;
}

.total-price {
  font-size: x-large!important;
  font: bolder!important;
  color: green;
}

.sub-header {
  font-size: small;
  font-style: italic;
}
</style>
