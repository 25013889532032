<template>
  <v-dialog v-model="model">
    <v-container fluid class="scrollbar">
      <v-card color="canvas">
        <v-card-title>
          <div>
            <div>{{ product.name }}</div>
            <div v-if="product.supplier" class="text-caption">{{ product.supplier.name }}</div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-if="isProductDeselected"
            density="compact"
            variant="outlined"
            icon="mdi-alert-circle-outline"
            color="accent"
          >
            <v-row align="center">
              <v-col>
                <div>{{ deselectionReason }}</div>
              </v-col>
              <v-col cols="auto">
                <v-chip size="small" label color="accent">FRAVALGT</v-chip>
              </v-col>
            </v-row>
          </v-alert>
          <v-expansion-panels multiple v-model="panel" class="text-black">
            <v-expansion-panel v-if="product.description">
              <v-expansion-panel-title>
                Beskrivelse af Servicens overordnede funktionalitet
              </v-expansion-panel-title>
              <v-expansion-panel-text>{{ product.description }}</v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel v-if="allUseCases && allUseCases.length > 0">
              <v-expansion-panel-title>
                Leverandørens use case besvarelser
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <p>
                  Læs leverandørens besvarelser af, hvordan use cases er opfyldt. <br />
                  Dine valgte use cases er markeret 'Valgt'. Der kan være flere use cases i listen, hvis denne service indeholder flere use cases.<br />
                  Nedenfor under ’Servicevarianter’ kan du se, hvilke use cases er indeholdt i den enkelte servicevariant.<br />
                </p>
                <v-table>
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">Use case</th>
                        <th class="text-left">Besvarelse</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="useCase in allUseCases"
                        :key="useCase.useCase_0219?.useCaseNumber_0219"
                      >
                        <td>
                          {{ useCase.useCase_0219?.useCaseNumber_0219 }}
                          <div class="px-3 py-1"><v-chip v-if="partOfSelectedUseCases(useCase.useCase_0219?.useCaseNumber_0219)" class="mx-1 bg-primary" size="small">Valgt</v-chip></div>
                        </td>
                        <td class="py-2">{{ useCase.useCaseDescription_0219 }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-table>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel v-if="functionalAddOns && functionalAddOns.length > 0">
              <v-expansion-panel-title>
                Funktionelle Tillægsydelser (FT)
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <p>
                  Angiv hvilke af leverandørens tilbudte funktionelle tillægsydelser, du ønsker at tilkøbe.
                </p>
                <div v-for="(addOn, idx) in uniqueFunctionalAddOns" :key="idx">
                  <v-divider></v-divider>
                  <v-row align="center">
                    <v-col cols="10">
                      {{ `${addOn.functionalAddOnId_0219} ${addOn.name}` }}
                      <div v-if="addOn.description">
                        <small>{{ addOn.description }}</small>
                      </div>
                    </v-col>
                    <v-col>
                      <v-radio-group
                        :disabled="isProductDeselected"
                        inline
                        hide-details
                        v-model="selectedFunctionalAddOns
                          .filter(s => s.id === addOn.id)
                          .first().selected"
                        @update:model-value="functionalAddOnUpdate(selectedFunctionalAddOns
                          .filter(s => s.id === addOn.id)
                          .first())"
                      >
                        <v-radio label="Ja" :value="true"></v-radio>
                        <v-radio label="Nej" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
            <v-expansion-panel v-if="supplierSpecificIntegrations && supplierSpecificIntegrations.length > 0">
              <v-expansion-panel-title>
                Leverandørspecifikke Integrationer (LSI)
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <p>
                  Angiv hvilke af leverandørens tilbudte leverandørspecifikke integrationer, du ønsker at tilkøbe.
                </p>
                <div v-for="(supplierSpecificIntegration, idx) in uniqueSupplierSpecificIntegration" :key="idx">
                  <v-divider></v-divider>
                  <v-row align="center">
                    <v-col cols="10">
                      {{ supplierSpecificIntegration.name }}
                      <div v-if="supplierSpecificIntegration.description">
                        <small>{{ supplierSpecificIntegration.description }}</small>
                      </div>
                    </v-col>
                    <v-col>
                      <v-radio-group
                        :disabled="isProductDeselected"
                        inline
                        hide-details
                        v-model="selectedSupplierSpecificIntegrations
                          .filter(s => s.id === supplierSpecificIntegration.id)
                          .first().selected"
                        @update:model-value="supplierSpecificIntegrationUpdate(selectedSupplierSpecificIntegrations
                          .filter(s => s.id === supplierSpecificIntegration.id)
                          .first())"
                      >
                        <v-radio label="Ja" :value="true"></v-radio>
                        <v-radio label="Nej" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-title>
                Servicevarianter
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <p>
                  Servicevarianter, der opfylder dine behov. <br />
                  En service kan findes i flere servicevarianter. Den enkelte servicevariant kan indeholde flere use cases, end du har valgt. Disse følger automatisk med. <br />
                  Du kan læse om de enkelte use cases øverst i 'Leverandørens use case besvarelser'.<br />
                </p>
                <v-expansion-panels multiple v-model="panel">
                  <v-expansion-panel
                    v-for="variant in uniqueVariants"
                    :key="variant.id"
                    class="bg-canvas"
                  >
                    <v-expansion-panel-title class="text-subtitle-1">
                      <div class="d-flex align-center">
                        <div>{{ variant.serviceVariantName_0219 }}</div>
                      </div>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <strong>Serviceniveau</strong>
                      <PimIntegrationServiceLevelChips v-if="variant.serviceVariantName_0219" :serviceLevels="variantServiceLevels(variant)" />
                      <br />
                      <strong>Use cases</strong>
                      <div
                        v-for="useCase in variant.useCases_0219"
                        :key="useCase.useCaseNumber_0219"
                      >
                        {{ useCase.useCaseNumber_0219 }} -
                        {{ useCase.useCaseName_0219 }}
                        <v-chip v-if="partOfSelectedUseCases(useCase.useCaseNumber_0219)" class="mx-1 bg-primary" size="small">Valgt</v-chip>
                      </div>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions v-if="!isProductDeselected">
          <v-spacer></v-spacer>
          <v-btn
            class="secondary-button-mini"
            @click="model = false"
          >Annuller</v-btn>
          <v-btn
            class="primary-button-mini"
            @click="onSave()"
          >Gem</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import {
  Tender,
} from '@dims/components';
import cloneDeep from 'lodash/cloneDeep';
import { Variant0219 } from '@/models/PIM/Variant0219';
import { FunctionalAddOn0219 } from '@/models/PIM/FunctionalAddOn0219';
import { SupplierSpecificIntegration0219 } from '@/models/PIM/SupplierSpecificIntegration0219';
import { Product0219 } from '@/models/PIM/Product0219';
import PimIntegrationServiceLevelChips from './PimIntegrationServiceLevelChips.vue';
import { pimIntegrationHelper } from '@/services/pimIntegrationHelper';
import pimService from '@/services/pim.service';

const emit = defineEmits<{ save: [Variant0219[], Product0219] }>();
const { tender, product, filteredVariants, functionalAddOnsRefs, supplierSpecificIntegrationsRefs, requestedUseCases } = defineProps<{
  tender: Tender,
  product: Product0219,
  filteredVariants: Variant0219[],
  functionalAddOnsRefs: FunctionalAddOn0219[],
  supplierSpecificIntegrationsRefs: SupplierSpecificIntegration0219[],
  requestedUseCases: string[] }>();
const model = defineModel<boolean>();

const functionalAddOns = ref<FunctionalAddOn0219[]>([]);
const supplierSpecificIntegrations = ref<SupplierSpecificIntegration0219[]>([]);
const selectedFunctionalAddOns = ref<FunctionalAddOn0219[]>([]);
const selectedSupplierSpecificIntegrations = ref<SupplierSpecificIntegration0219[]>([]);
const panel = ref([]);

const variants = computed(() => pimIntegrationHelper.getVariantsByProduct(filteredVariants, product));

const isProductDeselected = computed(() => variants.value.every((v) => v.deselectionReason));

const uniqueVariants = computed(() => pimIntegrationHelper.uniqueVariants(variants.value, product));

const allUseCases = computed(() => {
  const uc = uniqueVariants.value.flatMap((x) => x.useCases_0219).map((x) => x?.useCaseNumber_0219);
  return product.useCasesDescriptionTable_0219?.filter((x) => uc.includes(x.useCase_0219?.useCaseNumber_0219));
});

const uniqueFunctionalAddOns = computed(() => functionalAddOns.value.filter((v, i, a) => a.findIndex((t) => (t.name === v.name)) === i));

const uniqueSupplierSpecificIntegration = computed(() => supplierSpecificIntegrations.value.filter((v, i, a) => a.findIndex((t) => (t.name === v.name)) === i));

const deselectionReason = computed(() => pimIntegrationHelper.deselectionReason(variants.value, product));

function partOfSelectedUseCases(useCaseNumber: string | undefined) {
  if (!useCaseNumber) return false;
  return requestedUseCases.includes(useCaseNumber);
}

watch(
  model,
  () => {
    const productId = product.id.toString();

    updateFunctionalAddOns(productId);
    updateSelectedFunctionalAddOns();

    updateSupplierSpecificIntegrations(productId);
    updateSelectedSupplierSpecificIntegrations();

    // Reset panel if dialog is closed
    if (!model.value) {
      panel.value = [];
    }
  },
);

function updateFunctionalAddOns(productId: string) {
  const addOns = functionalAddOnsRefs.filter((f) => f.supplierProductId && f.supplierProductId.includes(productId));
  const filteredFunctionalAddOns = pimService.filterReferencesFromTenderData(addOns, tender.data);
  selectedFunctionalAddOns.value = cloneDeep(filteredFunctionalAddOns);
  functionalAddOns.value = filteredFunctionalAddOns;
}

function updateSupplierSpecificIntegrations(productId: string) {
  const integrations = supplierSpecificIntegrationsRefs.filter((i) => i.supplierProductId && i.supplierProductId.includes(productId));
  const filteredSupplierSpecificIntegrations = pimService.filterReferencesFromTenderData(integrations, tender.data);
  selectedSupplierSpecificIntegrations.value = cloneDeep(filteredSupplierSpecificIntegrations);
  supplierSpecificIntegrations.value = filteredSupplierSpecificIntegrations;
}

function updateSelectedSupplierSpecificIntegrations() {
  const { selectedSupplierSpecificIntegrations: selectedSupplierSpecificIntegrationsConst } = variants.value.first();
  if (selectedSupplierSpecificIntegrationsConst) {
    selectedSupplierSpecificIntegrations.value = supplierSpecificIntegrations.value.map((f) => ({
      ...f,
      selected: selectedSupplierSpecificIntegrationsConst.find((s) => s.name === f.name)?.selected,
    }));
  }
}

function updateSelectedFunctionalAddOns() {
  const { selectedFunctionalAddOns: selectedFunctionalAddOnsConst } = variants.value.first();
  if (selectedFunctionalAddOnsConst) {
    selectedFunctionalAddOns.value = functionalAddOns.value.map((f) => ({
      ...f,
      selected: selectedFunctionalAddOnsConst.find((s) => s.name === f.name)?.selected,
    }));
  }
}

function onSave() {
  model.value = false;
  variants.value.map((v) => {
    const variant = v;
    variant.selectedFunctionalAddOns = selectedFunctionalAddOns.value.filter((x) => x.serviceLevel_0219 === v.serviceLevel_0219).filter(
      (addOn, index, array) => array.findIndex((t) => t.name === addOn.name) === index,
    );
    variant.selectedSupplierSpecificIntegrations = selectedSupplierSpecificIntegrations.value.filter((x) => x.serviceLevel_0219 === v.serviceLevel_0219).filter(
      (integration, index, array) => array.findIndex((t) => t.name === integration.name) === index,
    );
    return v;
  });
  emit('save', variants.value, product);
}

function variantServiceLevels(variant: Variant0219): string[] {
  return variants.value
    .filter((v) => v.serviceVariantName_0219 === variant.serviceVariantName_0219)
    .map((v) => v.serviceLevel_0219)
    .filter((serviceLevel) => serviceLevel !== undefined);
}

function functionalAddOnUpdate(functionalAddOn: FunctionalAddOn0219) {
  const values = selectedFunctionalAddOns.value.filter((s) => s.name === functionalAddOn.name);
  values.map((v) => {
    const value = v;
    value.selected = functionalAddOn.selected;
    return value;
  });
}

function supplierSpecificIntegrationUpdate(supplierSpecificIntegration: SupplierSpecificIntegration0219) {
  const values = selectedSupplierSpecificIntegrations.value.filter((s) => s.name === supplierSpecificIntegration.name);
  values.map((v) => {
    const value = v;
    value.selected = supplierSpecificIntegration.selected;
    return value;
  });
}

</script>

<style scoped>
.v-expansion-panel-title {
  font-family: Oswald;
  font-size: 1.2rem;
}

.v-expansion-panel {
  color: black;
}

hr { margin: 1em 0;}

.scrollbar {
  overflow-y: scroll;
}

</style>
