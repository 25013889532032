<template>
  <div>
    <div v-if="files">
      <div v-if="!store.isSupplier">
        <div v-for="(file, ix) in filesForCustomer" :key="ix">
          <DownloadWordAttachment
            v-if="file.template"
            :wordTemplate="file.template"
            :questionnaire="questionnaire"
            :asCustomer="true"
          />
          <div v-if="file.attachment">
            <button type="button" @click="download(file)" class="link">
              <v-icon>mdi-file-download-outline</v-icon>
              <span class="ml-1">{{ file.displayName }}</span>
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="(file, ix) in filesForSupplier" :key="ix">
          <DownloadWordAttachment
            v-if="file.template"
            :wordTemplate="file.template"
            :questionnaire="questionnaire"
            :asCustomer="false"
          />
          <div v-if="file.attachment">
            <button type="button" @click.prevent="download(file)" class="link">
              <v-icon>mdi-file-download-outline</v-icon>
              <span class="ml-1">{{ file.displayName }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <v-progress-circular v-if="isLoading" indeterminate />
  </div>
</template>

<script setup lang="ts">
// eslint-disable-next-line max-classes-per-file
import {
  ActiveWordTemplate,
  DeepQuestionnaire,
  FilesQuestionFile,
  Tender,
  useBackend,
  useStore,
  DownloadWordAttachment,
  downloadFile,
} from '@dims/components';
import { ref, onMounted, computed } from 'vue';

/*
  Show all word templates and customer uploaded files in the specification
  (But not standard SKI files from the questionnaire template)

  Collected in a single list, since they have to be sorted alphabetically
*/
const { tender } = defineProps<{ tender: Tender }>();

const customerSpecificAttachmentQuestionIds = [
  'df46436b-2b74-4990-90da-f97c5f9635e6',
];

interface FileDisplay {
  displayName: string;
  template?: ActiveWordTemplate;
  attachment?: FilesQuestionFile;
  isDeepQuestionnaireTemplateFile: boolean;
}

class TemplateFile implements FileDisplay {
  constructor(readonly template: ActiveWordTemplate, readonly isDeepQuestionnaireTemplateFile: boolean) {}
  get displayName() {
    return this.template.name;
  }
}

class AttachmentFile implements FileDisplay {
  constructor(readonly attachment: FilesQuestionFile, readonly isDeepQuestionnaireTemplateFile: boolean) {}
  get displayName() {
    return this.attachment.displayName;
  }
}

const backend = useBackend();
const store = useStore();
const isLoading = ref(false);
// initialized in mounted
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const questionnaire = ref<DeepQuestionnaire>(null!);
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const files = ref<FileDisplay[]>(null!);

onMounted(async () => {
  isLoading.value = true;
  questionnaire.value = await fetchQuestionnaire();
  // templates
  const templates = await backend.deepQuestionnaireService.getActiveTemplates(
    questionnaire.value.id,
  );
  const templateFiles: FileDisplay[] = templates.map(
    (t) => new TemplateFile(t, false),
  );
    // file downloads (SKI files)
  const fileDownloadList = await backend.deepQuestionnaireService.getFileDownloadList(questionnaire.value.id);
  const fileDownloads = fileDownloadList.map((file) => new AttachmentFile(file, true));
  // uploads
  const attachments = await backend.deepQuestionnaireService.getSupplierUploads(questionnaire.value.id);
  const attachmentFiles = attachments.map((a) => new AttachmentFile(a, false));
  files.value = templateFiles
    .concat(attachmentFiles)
    .concat(fileDownloads)
    .sort((a, b) => (a.displayName < b.displayName ? -1 : 1));
  isLoading.value = false;
});

async function fetchQuestionnaire() {
  return backend.deepQuestionnaireService.getReadonlyQuestionnaire(
    tender.id,
    'specification',
  );
}

async function download(fileDisplay?: FileDisplay) {
  if (fileDisplay) {
    const { attachment } = fileDisplay;
    if (attachment) {
      if (fileDisplay.isDeepQuestionnaireTemplateFile) {
        try {
          const blob = await backend.deepQuestionnaireService.downloadFileFromFileDownloadQuestion(
            attachment.deepQuestionnaireTemplateId,
            attachment.questionId,
            attachment.displayName,
          );
          downloadFile(blob, attachment.displayName);
        } catch (e) {
          console.error(e);
          store.setSnackbarText('Kan ikke hente fil');
        }
      } else {
        try {
          const blob = await backend.deepQuestionnaireService.downloadAttachment(
            questionnaire.value.id,
            attachment.questionId,
            attachment.displayName,
          );
          downloadFile(blob, attachment.displayName);
        } catch (e) {
          console.error(e);
          store.setSnackbarText('Kan ikke hente fil');
        }
      }
    }
  }
}

const filesForSupplier = computed(() => files.value.filter(
  (f) => !customerSpecificAttachmentQuestionIds.includes(
    f.attachment?.questionId ?? '',
  ),
));

const filesForCustomer = computed(() => files.value);

</script>
