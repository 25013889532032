<template>
  <div>
    <v-card color="#fff9ef" flat>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="d-flex align-center">
              <h2 class="mt-0 mr-2">
                {{ selectedVariant.serviceVariantName_0219 }}
                ({{ selectedOffer?.supplier.name }}, {{ selectedProduct?.name }})
              </h2>
              <PimIntegrationServiceLevelChips :serviceLevels="[selectedVariant.serviceLevel_0219 ?? 'ukendt']" />
            </div>
            <br />
            <div>
              <div class="d-flex align-center">
                <StepStatus :complete="implementationType !== null" :label="1" />
                <h3 class="ma-2">Angiv implementeringstype</h3>
              </div>
              <v-radio-group v-model="implementationType" :disabled="isReadOnly" hide-details>
                <v-radio value="ExistingImplementation">
                  <template #label>
                    <div class="d-flex">
                      Type 1: Servicen er allerede i brug i min organisation
                      <DimsTooltip>
                        Vælg denne mulighed hvis den service leverandøren udbyder allerede er i brug i din organisation og implementeringen
                        er en opdatering eller udvidelse af noget eksisterende.
                      </DimsTooltip>
                    </div>
                  </template>
                </v-radio>
                <v-radio value="NewImplementation">
                  <template #label>
                    <div class="d-flex">
                      Type 2: Servicen er ikke før brugt i min organisation
                      <DimsTooltip>
                        Vælg denne mulighed hvis den service leverandøren udbyder er ny i din organisation og implementeringen er en installation helt fra grunden.
                      </DimsTooltip>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <v-col cols="auto">
            <v-card max-width="300" v-if="readyForCalculation && totalPrice()">
              <v-card-text>
                <div class="d-flex flex-column align-end">
                  <h2 class="mt-0 mb-2 font-weight-bold">{{ totalPrice() }}</h2>
                  <p>Evalueringsteknisk pris</p>
                </div>
                <div class="d-flex justify-center">
                  <v-dialog style="width: 1400px;" scrollable>
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="secondary-button-mini"
                      >
                        Se beregning
                      </v-btn>
                    </template>
                    <PriceDetails
                      :document="selectedVariant.priceResult?.document"
                    />
                  </v-dialog>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="d-flex align-center mt-3">
              <StepStatus :complete="educationCountsValid" :label="2" />
              <h3 class="ma-2">Angiv uddannelsesbehov</h3>
              <DimsTooltip>
                Bemærk for alle services, som ikke er brugt før i
                organisationen, skal angives det samme antal uddannelsespakker.
                Derfor kopieres tallet til andre Servicevarianter som er angive
                som TYPE 2 Implementering. For servicevarianter TYPE 1
                implementering er angivet, har man mulighed for angive et mindre
                uddannelsesbehov.
              </DimsTooltip>
            </div>
            <v-card class="mt-3">
              <v-form ref="educationCountForm">
                <v-table class="px-2">
                  <thead>
                    <tr>
                      <th class="text-left">
                        Område
                      </th>
                      <th class="text-left">
                        Train the trainer
                      </th>
                      <th class="text-left">
                        Superbrugeruddannelse
                      </th>
                      <th class="text-left">
                        Slutbrugeruddannelse
                      </th>
                      <th class="text-left">
                        Slutbrugeruddannelse (refresh)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ educationCountsService.area === 'Service' ? 'Uddannelsesbehov for servicen' : educationCountsService.area }}</td>
                      <td class="pt-5"><NumericInput density="compact" variant="outlined" :readonly="isReadOnly" data-cy="trainTheTrainer" v-model="educationCountsService.trainTheTrainer" :required="true" /> </td>
                      <td class="pt-5"><NumericInput density="compact" variant="outlined" :readonly="isReadOnly" data-cy="superuserEducation" v-model="educationCountsService.superuserEducation" :required="true" /> </td>
                      <td class="pt-5"><NumericInput density="compact" variant="outlined" :readonly="isReadOnly" data-cy="enduserEducation" v-model="educationCountsService.enduserEducation" :required="true" /> </td>
                      <td class="pt-5"><NumericInput density="compact" variant="outlined" :readonly="isReadOnly" data-cy="enduserEducationRefresh" v-model="educationCountsService.enduserEducationRefresh" :required="true" /> </td>
                    </tr>
                    <tr v-for="(item, idx) in educationCountsFT" :key="idx">
                      <td>{{ item.area }}</td>
                      <td class="pt-5"><NumericInput density="compact" variant="outlined" :readonly="isReadOnly" :data-cy="`trainTheTrainer-${idx}`" v-model="item.trainTheTrainer" :required="true" /> </td>
                      <td class="pt-5"><NumericInput density="compact" variant="outlined" :readonly="isReadOnly" :data-cy="`superuserEducation-${idx}`" v-model="item.superuserEducation" :required="true" /> </td>
                      <td class="pt-5"><NumericInput density="compact" variant="outlined" :readonly="isReadOnly" :data-cy="`enduserEducation-${idx}`" v-model="item.enduserEducation" :required="true" /> </td>
                      <td class="pt-5"><NumericInput density="compact" variant="outlined" :readonly="isReadOnly" :data-cy="`enduserEducationRefresh${idx}`" v-model="item.enduserEducationRefresh" :required="true" /> </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>
              <div class="d-flex align-center">
                <StepStatus :complete="productCalculationUnitsValid" :label="3" />
                <h3 class="ma-2">Angiv beregningsenheder</h3>
                <SimpleInfoDialog>
                  <p>Nødvendige oplysninger til prisberegning. Værdierne i denne blok er fælles for alle services.</p>
                  <p>Beregningsenheder pr. opgørelsesmåned.</p>
                  <p>Har du brug for mere information om beregningsenhederne se da filen
                    <a href="https://www.ski.dk/udbud/se-udbud/?id=02190223" target="_blank" rel="noopener noreferrer">'02.19 (2023) Beregningsenheder - oversigt'</a>
                    på aftalesiden på ski.dk under 'Aftaledokumenter - vejledning'.</p>
                </SimpleInfoDialog>
              </div>
              <v-form ref="productCalculationUnitsForm">
                <div class="my-3" v-for="(cu) in productCalculationUnits" :key="cu.calculationUnitValue.id">
                  <v-text-field
                    v-if="cu.visible"
                    v-model.number="cu.calculationUnitValue.count"
                    :label="cu.calculationUnitValue?.name"
                    density="compact"
                    variant="outlined"
                    flat
                    :readonly="isReadOnly"
                    required
                    :rules="[() => !!cu.calculationUnitValue.count || 'Feltet er påkrævet']"
                    color="white"
                    hide-details
                    data-cy="calculationUnitInput"
                  >
                    <template #append>
                      <DimsTooltip>
                        <div>
                          Definition: {{ cu.calculationUnitValue?.description }}<br />
                          Opgørelsestype: {{ cu.calculationUnitValue?.type }}
                        </div>
                      </DimsTooltip>
                    </template>
                  </v-text-field>
                </div>
              </v-form>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-expansion-panels variant="accordion">
              <v-expansion-panel class="bg-canvas">
                <v-expansion-panel-title class="text-primary">
                  <h4 class="mt-0">Use cases - se hvilke use cases denne servicevariant indeholder</h4>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <span class="sub-header">Valgte use-cases fra specifikationen er markeret med *</span>
                  <div class="mt-2" v-for="usecase in selectedVariant.useCases_0219" :key="usecase.useCaseNumber_0219">
                    <ul>
                      <li :class="{ dim: !useCaseIsMandatory(usecase.useCaseNumber_0219 ?? '') }"><span v-if="useCaseIsMandatory(usecase.useCaseNumber_0219 ?? '')">*</span> {{ usecase.useCaseNumber_0219 }}
                        <ul>
                          <li>{{ usecase.useCaseName_0219 }}</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row no-gutters class="mt-3">
      <v-col>
        <v-row v-if="!allReady || !educationCountsValid || !productCalculationUnitsValid" class="mt-5" no-gutters>
          <v-col cols="auto"><v-icon class="mr-2">mdi-information-outline</v-icon></v-col>
          <v-col>Færdiggør trin 1-3 for alle servicevarianter. Herefter trin 4-6 for den vindende servicevariant.</v-col>
        </v-row>
        <template v-else>
          <small v-if="bestVariant === selectedVariant">Billigste servicevariant</small>
          <v-btn
            v-else-if="bestVariant"
            @click="emit('selectVariant', bestVariant)"
            color="accent"
            class="px-2 text-decoration-underline"
            variant="text"
          >
            Gå til billigste servicevariant
          </v-btn>
          <v-card v-if="bestVariant" style="overflow: hidden" color="#D4E3DC" flat>
            <v-card-text>
              <v-row>
                <v-col><div class="ml-10">{{ bestVariant.serviceVariantName_0219 }} ({{ bestVariant.supplierName }})</div></v-col>
                <v-col cols="auto"><PimIntegrationServiceLevelChips :serviceLevels="[bestVariant.serviceLevel_0219 ?? 'ukendt']" /></v-col>
                <WinnerTag
                  :isWinner="isReadOnly"
                  :avatarSize="55"
                  class="winner-tag"
                  tagClass="pl-1 pt-1"
                />
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-row v-if="allReady && isReadyForRiskAssessment">
      <v-col>
        <WinnerFinalization
          :isReadyForRiskAssessment="isReadyForRiskAssessment"
          :tender="tender"
          :selectedOffer="selectedOffer"
          :selectedVariant="selectedVariant"
          :selectedProduct="selectedProduct"
          :isBestPrice="isBestPrice"
          @updateTab="emit('updateTab', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { useBackend, DimsTooltip, CalculationUnitValue,
  DeepQuestionnaire, TenderStateEnum, useDirectAwardStore, SimpleInfoDialog, toCurrencyFormat } from '@dims/components';
import { computed, ref, watch, onMounted } from 'vue';
import { VForm } from 'vuetify/components';
import { Product0219 } from '@/models/PIM/Product0219';
import { Tender0219, SystemTypesEnum } from '@/models';
import WinnerFinalization from './WinnerFinalization.vue';
import PriceDetails from './PriceDetails.vue';
import { deepQuestionnaireHelper0219 } from '@/services/deepQuestionnaireHelper0219';
import { CalculationUnitValueLocal, ExtendedVariant } from '@/models/PIM/Variant0219';
import PimIntegrationServiceLevelChips from '../Specification/PimIntegration/PimIntegrationServiceLevelChips.vue';
import WinnerTag from './WinnerTag.vue';
import { EducationCount } from '@/models/EducationCount';
import { DirectOffer0219, ImplementationType } from '@/models/DirectOffer';

const emit = defineEmits<{
  calculationUnitCounts: [CalculationUnitValue[]],
  educationCountsAddon: [EducationCount[], DirectOffer0219],
  educationCountsService: [EducationCount, DirectOffer0219],
  implementationType: [ImplementationType, number],
  updateTab: [string],
  selectVariant: [ExtendedVariant]
  }>();
const {
  tender,
  selectedVariant,
  inconclusive = false,
  bestVariant = undefined,
  selectedProduct,
  initialEducationCountsFT,
  initialEducationCountsSVC,
  initialCalculationUnitCounts,
  calculationUnitCounts,
  relevantCalculationUnits,
  allVariants,
} = defineProps<{
  tender: Tender0219,
  selectedVariant: ExtendedVariant,
  bestVariant?: ExtendedVariant | undefined,
  selectedProduct: Product0219 | null,
  initialEducationCountsFT: EducationCount[],
  initialEducationCountsSVC: EducationCount,
  initialCalculationUnitCounts: CalculationUnitValue[],
  calculationUnitCounts: CalculationUnitValue[],
  relevantCalculationUnits: CalculationUnitValue[],
  allVariants: ExtendedVariant[],
  inconclusive?: boolean,
}>();
const selectedOffer = computed((): DirectOffer0219|undefined => selectedVariant.directOffer);
const directAwardStore = useDirectAwardStore();
const backend = useBackend();
const useCases = ref<string[]>([]);
const service = 'Service';
const educationCountsValid = ref(false);
const productCalculationUnitsValid = ref(false);
const debounceSave1 = ref(0);
const debounceSave2 = ref(0);
const debounceSave3 = ref(0);
const questionnaire = ref<DeepQuestionnaire>();

const isReadOnly = computed(() => tender.state !== TenderStateEnum.Evaluate);

const implementationType = computed({
  get(): ImplementationType | null {
    return selectedOffer.value?.implementationType ?? null;
  },
  set(value: ImplementationType | null) {
    if (value) {
      emit('implementationType', value, selectedVariant.productId);
    }
    void validateForm();
  },
});

const productCalculationUnits = computed((): CalculationUnitValueLocal[] => {
  const calcUnits = calculationUnitCounts.map((p) => ({ calculationUnitValue: p,
    visible: relevantCalculationUnits.includes(p) }));
  return calcUnits;
});

const educationCountsService = computed((): EducationCount => selectedOffer.value?.educationCounts?.find((x) => x.area === service) ?? { refId: selectedVariant.id, area: service });

const educationCountsFT = computed((): EducationCount[] => selectedOffer.value?.educationCounts?.filter((x) => x.area !== service) ?? []);

const readyForCalculation = computed((): boolean => selectedVariant.readyForCalculation());

const allReady = computed(() => allVariants.every((x) => x.readyForCalculation()));

watch(
  allReady,
  (newVal: boolean) => {
    directAwardStore.setEvaluationCompleted(newVal);
  },
);

const isReadyForRiskAssessment = computed((): boolean => (selectedVariant.readyForCalculation() && selectedVariant.id === allVariants.filter((x) => x.directOffer?.riskAssessmentAccepted !== false)[0]?.id && !inconclusive) || (selectedVariant.directOffer?.riskAssessmentAccepted === false));

const isBestPrice = computed((): boolean => selectedVariant.id === allVariants.filter((x) => x.directOffer?.riskAssessmentAccepted !== false)[0]?.id);

function totalPrice(): string|undefined {
  const price = selectedVariant.priceResult?.price;
  return toCurrencyFormat(price ?? undefined);
}

const educationCountForm = ref<VForm>();

async function isEducationCountsValid() {
  if (!educationCountForm.value) { return false; }
  const validationResult = await educationCountForm.value.validate();
  return validationResult.valid;
}

const productCalculationUnitsForm = ref<VForm>();

async function isProductCalculationUnitsValid() {
  if (!productCalculationUnitsForm.value) { return false; }
  const validationResult = await productCalculationUnitsForm.value.validate();
  return validationResult.valid;
}

async function validateForm() {
  educationCountsValid.value = await isEducationCountsValid();
  productCalculationUnitsValid.value = await isProductCalculationUnitsValid();
}

watch(
  educationCountsService,
  () => {
    if (debounceSave3.value) {
      window.clearTimeout(debounceSave3.value);
    }
    debounceSave3.value = window.setTimeout(() => {
      void emitChangeSVC(educationCountsService.value);
    }, 2000);
  },
  { deep: true },
);

async function emitChangeSVC(counts: EducationCount) {
  await validateForm();
  if (educationCountsChanged(counts, initialEducationCountsSVC)
      && selectedOffer.value && educationCountsValid.value) {
    emit('educationCountsService', counts, selectedOffer.value);
  }
}

watch(
  educationCountsFT,
  async () => {
    await validateForm();
    if (debounceSave2.value) {
      window.clearTimeout(debounceSave2.value);
    }
    debounceSave2.value = window.setTimeout(() => {
      void emitChangeFT(educationCountsFT.value);
    }, 2000);
  },
  { deep: true },
);

async function emitChangeFT(counts: EducationCount[]) {
  await validateForm();
  if (counts.some((c) => educationCountsChanged(c, initialEducationCountsFT.find((e) => e.area === c.area)))
      && selectedOffer.value && educationCountsValid.value) {
    emit('educationCountsAddon', counts, selectedOffer.value);
  }
}

watch(
  productCalculationUnits,
  async () => {
    await validateForm();
    if (debounceSave1.value) {
      window.clearTimeout(debounceSave1.value);
    }
    debounceSave1.value = window.setTimeout(() => {
      emitChangeCU(productCalculationUnits.value);
    }, 2000);
  },
  { deep: true },
);

function emitChangeCU(counts: CalculationUnitValueLocal[]) {
  if (selectedOffer.value && calculationUnitCountsChanged(counts, initialCalculationUnitCounts)) {
    emit('calculationUnitCounts', counts.map((x) => x.calculationUnitValue));
  }
}

function educationCountsChanged(newCounts: EducationCount|undefined, oldcount: EducationCount|undefined):boolean {
  if (oldcount && newCounts) {
    if (oldcount.trainTheTrainer === newCounts.trainTheTrainer
      && oldcount.superuserEducation === newCounts.superuserEducation
      && oldcount.enduserEducation === newCounts.enduserEducation
      && oldcount.enduserEducationRefresh === newCounts.enduserEducationRefresh) return false;
  }
  return true;
}

function calculationUnitCountsChanged(newCounts: CalculationUnitValueLocal[]|undefined, oldcount: CalculationUnitValue[]|undefined):boolean {
  if (oldcount && newCounts) {
    if (oldcount.every((x) => x.count === newCounts.find((n) => n.calculationUnitValue.id === x.id)?.calculationUnitValue.count)) return false;
  }
  return true;
}

onMounted(async () => {
  // Load questionnarire values
  questionnaire.value = await fetchQuestionnaire();
  const { questions } = questionnaire.value;
  if (tender.data.systemType === SystemTypesEnum.PartI) {
    useCases.value = deepQuestionnaireHelper0219.loadUseCases(questions, deepQuestionnaireHelper0219.useCasesPartIQuestionId);
  } else if (tender.data.systemType === SystemTypesEnum.PartII) {
    useCases.value = deepQuestionnaireHelper0219.loadUseCases(questions, deepQuestionnaireHelper0219.useCasesPartIIQuestionId);
  }
  await validateForm();
});

function useCaseIsMandatory(useCase: string): boolean {
  return useCases.value.includes(useCase);
}

async function fetchQuestionnaire() {
  return backend.deepQuestionnaireService
    .getReadonlyQuestionnaire(
      tender.id,
      'specification',
    );
}

</script>

<style scoped>
.evaluation-board {
  display: flex;
  justify-content: space-between;
}

.dim {
  color: gray;
}

.sub-header {
  font-size: small;
  font-style: italic;
}

ul {
  list-style-type: square;
}

td {
  padding: 0 3px 0 3px !important;
}

.winner-tag {
  position: absolute;
  top: -12px;
  left: -12px;
  font-size: 35px;
}

</style>
