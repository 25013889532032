<!--
  DO NOT EDIT THIS FILE

  File is duplicated across sites, so should be kept in sync

  -------

  Sorry, but I have changed this file regardless of the above comment :-)
  So this file has been edited and is no longer in sync with other sites.
  Here is what was changed:

  PBI 22138:
    For 02.19 DIS (and only 02.19 DIS) the question "Ja, alle krav er opfyldt / Nej, alle krav er ikke opfyldt" has been removed as requested

-->
<template>
  <div>
    <v-form ref="form" :disabled="deadlineExpired">
      <v-container fluid>
        <div class="align-self-center mb-5" v-if="isDraft && deadlineExpired">
          <p class="text-black ma-0">
            Du kan ikke indsende tilbud, da tilbudsfristen er overskredet
          </p>
        </div>
        <div v-if="offerPublishedDate !== ''" class="mb-10">
          <p>
            <strong>Tilbud indsendt {{ offerPublishedDate }}</strong>
          </p>
          <div>
            <p v-if="!deadlineExpired">
              Du kan frem til tilbudsfristen trække dit tilbud tilbage og evt.
              tilrette og indsende et nyt. Det gør du under fanen 'Overblik'.
              Når tilbudsfristen er udløbet, kan du ikke længere ændre eller
              tilbagekalde dit tilbud
            </p>
          </div>
          <v-btn
            class="primary-button"
            @click="offerDetailsDialog = !offerDetailsDialog"
          >
            Se dit tilbud
          </v-btn>
          <v-dialog v-model="offerDetailsDialog" width="1400">
            <v-card v-if="offerDetailsDialog" class="bg-canvas px-5 pt-10">
              <v-card-text>
                <OfferSpecificationViewer :tender="tender" :offer="offer" />
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  @click="closeOfferDetailsDialog()"
                  class="mb-4 primary-button"
                >
                  Luk
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-row>
          <v-col>
            <v-card class="pa-3 bg-canvas">
              <v-card-text>
                <div class="d-flex">
                  <div>
                    <h1 class="mt-0 mb-2 font-weight-bold">
                      Udfyld dit tilbud
                    </h1>
                    <div class="mt-2 mb-7">
                      <p>
                        Kravbesvarelse og prisafgivelse herunder udgør dit
                        tilbud
                      </p>
                      <p>
                        Udfyld dit tilbud ved (1) at besvare evalueringskravene og
                        (2) afgive priser.
                      </p>
                      <p v-if="isDraft && error" class="text-error">
                        Kan ikke gemme kladde!
                      </p>
                    </div>
                  </div>
                </div>
                <v-card>
                  <v-card-text class="pa-10">
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus
                          :label="1"
                          :complete="isSectionComplete(specificationSections)"
                        />
                      </v-col>
                      <v-col>
                        <h2 class="mt-0 mb-2">Kravbesvarelse</h2>
                        <!-- diff start -->
                        <p>
                          Besvarelsen omhandler krav til servicen samt øvrige krav
                          som kunden måtte have tilvalgt.
                        </p>
                        <p>
                          Gennemlæs materialet inden du udfylder din besvarelse og
                          uploader bilagene.
                        </p>
                        <!-- diff end -->
                        <v-dialog v-model="offerPreviewDialog" max-width="95%">
                          <template #activator="{ props }">
                            <ArrowButton
                              :disabled="!offerQuestionnaire || !isDraft"
                              v-bind="props"
                              class="mt-2"
                              :class="
                                isSectionComplete(specificationSections) ? 'secondary' : ''
                              "
                            >Besvar evalueringskrav</ArrowButton>
                          </template>
                          <v-card>
                            <OfferDetailsForm
                              v-if="offerQuestionnaire"
                              :tender="tender"
                              :offerQuestionnaire="offerQuestionnaire"
                              :tabType="'SupplierSpecification'"
                              @closeDialog="closeOfferPreviewDialog"
                            />
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus
                          :label="2"
                          :complete="isSectionComplete([priceSection])"
                        />
                      </v-col>
                      <v-col>
                        <h2 class="mt-0 mb-2">Prisafgivelse</h2>
                        <!-- diff start -->
                        <p>
                          Prisafgivelsen er opdelt i en række priselementer, der
                          afspejler kundens behov.
                        </p>
                        <p>
                          Indledningsvist skal du vælge den beregningsenhed, du
                          ønsker er gældende for dit tilbud og dermed beregningen
                          af den evalueringstekniske pris.
                          <br />
                          Dernæst skal du angive en prisreguleringsprocent, som du
                          kan vælge at gøre brug af i kontraktens varighed, jf.
                          bilag 4.1.
                        </p>
                        <p>
                          Efterfølgende skal du afgive priser for hvert
                          priselement. Hvert priselement har én eller flere
                          pristabeller med prispunkter, du skal udfylde. I takt
                          med din udfyldelse, kan du se prisen aggregeret op pr.
                          pristabel og priselement.
                        </p>
                        <p>
                          I 'Opsummering' (til højre), kan du følge de priser, du
                          har givet, samt den evalueringstekniske pris.
                        </p>
                        <p>
                          <b>Bemærk</b> priser skal afgives ekskl. moms og inkl.
                          kundebetaling til SKI.
                          <DimsTooltip>
                            <p>
                              Leverandøren skal på vegne af Kunden videreformidle
                              kundebetalingen på 1,5 % af Kundens købssum til SKI.
                              Leverandøren skal derfor indregne kundens betaling
                              til SKI på 1,5 % i sine tilbudspriser i det konkrete
                              indkøb.
                            </p>
                            <p>Kundebetalingen beregnes efter følgende formel:</p>
                            <p class="text-decoration-underline">
                              Kundens købssum eksklusive moms i DKK x 1,5 % =
                              Kundebetalingen i DKK
                            </p>
                            <p>
                              Leverandørens rapportering samt formidling af
                              kundens betaling til SKI er beskrevet nærmere i
                              Bilag A Trepartsaftale.
                            </p>
                          </DimsTooltip>
                        </p>
                        <!-- diff end -->
                        <v-dialog v-model="offerFormDialog" max-width="95%">
                          <template #activator="{ props }">
                            <ArrowButton
                              :disabled="!offerQuestionnaire || !isDraft"
                              v-bind="props"
                              class="mt-2"
                              :class="isSectionComplete([priceSection]) ? 'secondary' : ''"
                            >Afgiv priser</ArrowButton>
                          </template>
                          <v-card>
                            <OfferDetailsForm
                              v-if="offerQuestionnaire"
                              :tender="tender"
                              :offerQuestionnaire="offerQuestionnaire"
                              :tabType="'SupplierOffer'"
                              @closeDialog="closeOfferFormDialog"
                            />
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus :label="3" :complete="espdFile !== undefined" />
                      </v-col>
                      <v-col>
                        <OfferDetailsESPD
                          :tender="tender"
                          :offer="offer"
                          :disabled="!validOffer || !isDraft"
                          :espdFile="espdFile"
                        />
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <v-row>
                      <v-col cols="auto">
                        <StepStatus :label="4" :complete="offerLetter !== undefined" />
                      </v-col>
                      <v-col>
                        <OfferDetailsOfferLetter
                          :tender="tender"
                          :offer="offer"
                          :disabled="!isDraft"
                          :offerLetter="offerLetter"
                        />
                      </v-col>
                    </v-row>
                    <v-divider class="my-8"></v-divider>
                    <v-row>
                      <v-col cols="auto">
                        <!-- diff start -->
                        <StepStatus :label="5" :complete="!isDraft" />
                        <!-- diff end -->
                      </v-col>
                      <v-col>
                        <h2 class="mt-0 mb-2">Se og send tilbud</h2>
                        <p v-if="!validOffer && isDraft">
                          <!-- diff start -->
                          Færdiggør trin 1-4 for at sende dit tilbud
                          <!-- diff end -->
                        </p>
                        <v-dialog
                          v-model="confirmationOfferDialog"
                          persistent
                          scrollable
                          max-width="80%"
                        >
                          <template #activator="{ props }">
                            <ArrowButton
                              :disabled="!validOffer || !isDraft"
                              v-bind="props"
                              class="mt-2"
                              data-cy="send-offer-button"
                            >Se og send tilbud</ArrowButton>
                          </template>
                          <v-card>
                            <SendOfferConfirmationDialog
                              @closeConfirmationDialog="
                                closeOfferConfirmationDialog
                              "
                              @makeOfferActive="makeOfferActive"
                              :offer="offer"
                              :tender="tender"
                            />
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4">
            <TcoCalculationSummary
              :questions="tcoCalculationQuestions"
              :tcoCalculationResult="tcoCalculationResult"
              cardStyle="#fff9ef"
              tableStyle="transparent"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <LoadingSpinner :loadingMessage="loadingMessage" :visible="isLoading || isSaving" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import {
  LoadingSpinner,
  Offer,
  Tender,
  UpdateOfferRequest,
  useStore,
  DeepQuestionnaireDebouncer,
  SendOfferConfirmationDialog,
  useBackend,
  dateUtils,
  PushDeepAnswer,
  DeepQuestionnaire,
  ArrowButton,
  tenderHelper,
  StepStatus,
  DeepQuestionnaireRoot,
  OfferDetailsOfferLetter,
  OfferDetailsForm,
  OfferDetailsESPD,
  DeepQuestionnaireWrapper,
} from '@dims/components';
import OfferSpecificationViewer from '../Specification/OfferSpecificationViewer.vue';
import TcoCalculationSummary from './TcoCalculation/TcoCalculationSummary.vue';
import { setSupplierPriceTab, setSupplierSpecificationTabs } from '../../../services/questionnaireTabConfig';

const { tender, offer } = defineProps<{
  tender: Tender,
  offer: Offer,
}>();
const store = useStore();
const backend = useBackend();
const offerQuestionnaire = ref<DeepQuestionnaireRoot>();
const confirmationOfferDialog = ref(false);
const offerDetailsDialog = ref(false);
const offerFormDialog = ref(false);
const offerPreviewDialog = ref(false);
const loadingMessage = ref('Vent venligst');
const isLoading = ref(false);
const isSaving = ref(false);
const error = ref(false);
// Diff start
const priceSection = ref(0);
const specificationSections = ref<number[]>([]);
// Diff end

// Questions used for offer status overview
// Exclude the first question (general information) from the summary
const tcoCalculationQuestions = computed(() => offerQuestionnaire.value?.questionnaire.questions[priceSection.value]?.questions?.filter((x) => x.enabled && !x.key?.includes('tcoExcludedQuestion')) ?? []);

const deadlineExpired = computed(() => tenderHelper.isDeadlineExpired(tender));

const questionnaireDebouncer = new DeepQuestionnaireDebouncer(
  (answers) => saveChanges(answers),
  (questionnaire) => { questionnaireUpdated(questionnaire); },
);

onMounted(async () => {
  await backend.deepQuestionnaireService.getReadonlyQuestionnaire(
    tender.id,
    'specification',
  );
  await getOrCreateOfferQuestionnaire();
});

async function getOrCreateOfferQuestionnaire() {
  const questionnaire = await backend.deepQuestionnaireService.getQuestionnaire(
    tender.id,
    'offer',
    offer.id,
  );
  questionnaireUpdated(questionnaire);
}

// Callback when questionnaire has changed
function questionnaireUpdated(questionnaire: DeepQuestionnaire) {
  offerQuestionnaire.value = new DeepQuestionnaireWrapper(
    questionnaire,
    store.backend.deepQuestionnaireService,
    (q) => { questionnaireDebouncer.questionUpdated(q); },
    (q) => { questionnaireUpdated(q); },
    () => questionnaireDebouncer.flushChanges(),
  );
  priceSection.value = setSupplierPriceTab(offerQuestionnaire.value.questionnaire);
  specificationSections.value = setSupplierSpecificationTabs(offerQuestionnaire.value.questionnaire);
  specificationSections.value = specificationSections.value.filter(
    (section) => section !== -1,
  );
}

// callback to save questionnaire changes
async function saveChanges(answers: PushDeepAnswer[]) {
  return backend.deepQuestionnaireService.updateQuestionnaireFromAnswers(
    tender.id,
    'offer',
    answers,
    offer.id,
  );
}

function closeOfferConfirmationDialog() {
  confirmationOfferDialog.value = false;
}

function closeOfferDetailsDialog() {
  offerDetailsDialog.value = false;
}

function closeOfferFormDialog() {
  offerFormDialog.value = false;
}

function closeOfferPreviewDialog() {
  offerPreviewDialog.value = false;
}

async function makeOfferActive(signerName: string) {
  closeOfferConfirmationDialog();
  if (offer.state === 'Draft' && signerName) {
    const update: UpdateOfferRequest = {
      data: {},
      signerName,
    };
    loadingMessage.value = 'Sender tilbud';
    isLoading.value = true;
    try {
      await store.updateOfferAction(offer, update);
      await store.publishOfferAction(offer);
      await backend.markerService.createMarker(offer);
    } catch (e) {
      store.setSnackbarText('Kan ikke sende tilbud');
    } finally {
      isLoading.value = false;
    }
    loadingMessage.value = 'Vent venligst';
  }
}

const validOffer = computed(() => (
  !tenderHelper.isDeadlineExpired(tender)
    && offerQuestionnaire.value?.complete
));

const isDraft = computed((): boolean => offer.state === 'Draft');

const offerPublishedDate = computed((): string => {
  const date = offer.publishedDate;
  return dateUtils.shortDateTimeFormat(date);
});

const tcoCalculationResult = computed(() => offerQuestionnaire.value?.questionnaire.tcoCalculationResult);

function isSectionComplete(sections: number[]) {
  const topLevelQuestions = offerQuestionnaire.value?.questionnaire.questions;
  return topLevelQuestions
    ? sections.every((section) => topLevelQuestions[section]?.questions?.every((q) => q.complete))
    : false;
}

const espdFile = computed(() => offer.artifacts?.find((x) => x.templateArtifactKey === 'espd' && x.templateKey === 'offer'));
const offerLetter = computed(() => offer.artifacts?.find((x) => x.templateArtifactKey === 'offerletter' && x.templateKey === 'offer'));

</script>
