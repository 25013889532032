<template>
  <div class="scroll">
    <v-card class="bg-canvas">
      <v-card-title>
        <h3>Basisoplysninger</h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h3>Vælg service:</h3>
            <v-radio-group
              v-model="selectedProduct"
              @update:model-value="onProductChanged(selectedProduct)"
            >
              <div v-for="(product, id) in products?.service_0219" :key="id">
                <v-radio :value="product">
                  <template #label>
                    {{ product.name }}
                    <DimsTooltip>
                      <p>
                        {{ product.description }}
                      </p>
                    </DimsTooltip>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </v-col>
          <v-col>
            <h3>Vælg variant:</h3>
            <v-radio-group
              v-model="selectedVariant"
              @update:model-value="onVariantChanged(selectedVariant)"
            >
              <div v-for="(variant, id) in variants" :key="id">
                <v-radio :value="variant">
                  <template #label>
                    {{ variant.serviceLevel_0219 }} -
                    {{ variant.serviceVariantName_0219 }}
                    <DimsTooltip>
                      <p>
                        {{ variant.useCases_0219?.map(x => x.useCaseNumber_0219) }}
                      </p>
                    </DimsTooltip>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </v-col>
          <v-col>
            <h3>Vælg kontraktlængde:</h3>
            <NumericInput
              v-model="priceParameterSet.contractLength"
              :min-value="1"
              :max-value="6"
              required
              variant="outlined"
            >
            </NumericInput>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="bg-canvas">
      <v-card-title>
        <h3>Optioner</h3>
      </v-card-title>
      <v-card-text>
        <v-checkbox v-model="priceParameterSet.option1" :disabled="!isVariantSelected()" density="comfortable" hide-details>
          <template #label>
            <div class="d-flex">
              Option 1
              <DimsTooltip>
                Vælg denne option, hvis du ønsker prisen skal indeholde mulighed for exitproces.
              </DimsTooltip>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox v-model="priceParameterSet.option2" :disabled="!isVariantSelected()" density="comfortable" hide-details>
          <template #label>
            <div class="d-flex">
              Option 2
              <DimsTooltip>
                Vælg denne option, hvis du ønsker prisen skal indeholde mulighed for opbevaring af data efter kontraktens ophør.
              </DimsTooltip>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox v-model="priceParameterSet.option3" :disabled="!isVariantSelected()" density="comfortable" hide-details>
          <template #label>
            <div class="d-flex">
              Option 3
              <DimsTooltip>
                Vælg denne option, hvis du ønsker prisen skal indeholde mulighed for arkivering.
              </DimsTooltip>
            </div>
            <NumericInput
              v-if="priceParameterSet.option3"
              v-model="priceParameterSet.option3Count"
              variant="outlined"
              hide-details
              prefix="Antal arkiveringer:"
              class="ml-2"
            >
            </NumericInput>
          </template>
        </v-checkbox>
      </v-card-text>
    </v-card>
    <v-card class="bg-canvas">
      <v-card-title>
        <h3>Implementeringstype</h3>
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="priceParameterSet.implementationType" :disabled="!isVariantSelected()">
          <v-radio value="ExistingImplementation">
            <template #label>
              <div class="d-flex">
                Type 1: Servicen er allerede i brug i min organisation
                <DimsTooltip>
                  Vælg denne mulighed hvis den service leverandøren udbyder allerede er i brug i din organisation og implementeringen
                  er en opdatering eller udvidelse af noget eksisterende.
                </DimsTooltip>
              </div>
            </template>
          </v-radio>
          <v-radio value="NewImplementation">
            <template #label>
              <div class="d-flex">
                Type 2: Servicen er ikke før brugt i min organisation
                <DimsTooltip>
                  Vælg denne mulighed hvis den service leverandøren udbyder er ny i din organisation og implementeringen er en installation helt fra grunden.
                </DimsTooltip>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <v-card class="bg-canvas">
      <v-card-title>
        <h3>Beregningsenheder</h3>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="calculationUnits"
          :items="calculationUnits"
          :headers="headers"
          dense
          hide-default-footer
          items-per-page="-1"
        >
          <template
            #item.count="{ item }"
          >
            <NumericInput class="py-1" density="compact" variant="outlined" v-model="item.count" hide-details />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
  <v-card>
    <v-card-title>
      <h3>Evalueringsteknisk pris</h3>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <span class="total-price">{{ $format.currency2(price.price) }}</span></v-col>
        <v-col>
          <div class="d-flex justify-end">
            <v-dialog style="width: 1400px;" scrollable>
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  class="secondary-button-mini"
                >
                  Se beregning
                </v-btn>
              </template>
              <PriceDetails
                :document="price.document"
              />
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <LoadingSpinner :loadingMessage="loadingMessage" :visible="isLoading" />
</template>

<script setup lang="ts">
import { NumericInput, PriceResult, useBackend, useStore } from '@dims/components';
import { computed, onMounted, ref, watch } from 'vue';
import { DateTime } from 'luxon';
import { FunctionalAddOn0219 } from '@/models/PIM/FunctionalAddOn0219';
import { SupplierSpecificIntegration0219 } from '@/models/PIM/SupplierSpecificIntegration0219';
import { Product0219 } from '@/models/PIM/Product0219';
import { Variant0219 } from '@/models/PIM/Variant0219';
import { PriceParameterSet } from '@/models/PriceParameter';
import PriceDetails from './PriceDetails.vue';

interface SupplierReferences_0219 {
  readonly service_0219: Product0219[];
  readonly functionalAddOn_0219: FunctionalAddOn0219[];
  readonly supplierSpecificIntegration_0219: SupplierSpecificIntegration0219[];
}
const headers = [
  { title: 'Enhed', value: 'name', width: '100px' },
  { title: 'Beskrivelse', value: 'description', width: '60%' },
  { title: 'Antal', value: 'count', width: '10%' },
];
const backend = useBackend();
const store = useStore();
const products = ref<SupplierReferences_0219|null>(null);
const variants = ref<Variant0219[]>([]);
const loadingMessage = ref('Henter dine services');
const isLoading = ref(false);
const selectedProduct = ref<Product0219|null>(null);
const selectedVariant = ref<Variant0219|null>(null);
const priceParameterSet = ref<PriceParameterSet>({});
const calculationUnits = computed(() => priceParameterSet.value.calculationUnitValues);
const price = ref<PriceResult>({});
const debounceCalc = ref(0);
onMounted(async () => {
  isLoading.value = true;
  if (store.user) {
    products.value = await backend.pimDataService.fetchMyProducts<SupplierReferences_0219>(store.agreementId);
  }
  isLoading.value = false;
});

watch(
  priceParameterSet,
  (p) => {
    if (p.contractLength && p.productId && p.variantId) {
      if (debounceCalc.value) {
        window.clearTimeout(debounceCalc.value);
      }
      debounceCalc.value = window.setTimeout(() => {
        loadingMessage.value = 'Beregner pris';
        isLoading.value = true;
        const parameters = { ...p };
        parameters.contractLength = (p.contractLength ?? 0) + 2;
        void backend.directOfferService.getSimplePrice(parameters)
          .then((result) => { price.value = result; isLoading.value = false; });
      }, 2000);
    }
  },
  { deep: true },
);

function isVariantSelected() : boolean {
  if (selectedProduct.value?.id && selectedVariant.value?.id) {
    return true;
  }
  return false;
}

async function onProductChanged(product: Product0219|null) {
  if (product) {
    loadingMessage.value = 'Henter servicevarianter';
    isLoading.value = true;
    variants.value = await backend.pimDataService.variantsFromProductId(product.id, store.agreementId);
    const references = await backend.pimDataService.fetchProductReferences(product.id);
    console.log(references);
    isLoading.value = false;
    priceParameterSet.value = {
      productId: product.id,
      name: `Testberegning af pris for "${product.name}" service`,
      contractDate: DateTime.now().toISODate(),
      priceRegulation: product.priceRegulation_0219,
      option1: false,
      option2: false,
      option3: false,

    };
    if (product.calculationUnitValue) {
      priceParameterSet.value.calculationUnitValues = [product.calculationUnitValue];
    }
  } else {
    variants.value = [];
    priceParameterSet.value = {};
  }
}

function onVariantChanged(variant: Variant0219|null) {
  if (variant) {
    priceParameterSet.value.variantId = variant.id;
    priceParameterSet.value.serviceName = `${variant.serviceVariantName_0219} (${selectedProduct.value?.supplier?.name})`;
    priceParameterSet.value.serviceLevel = variant.serviceLevel_0219;
  }
}

</script>

<style scoped>

.small-table {
  width: 50%;
}

.total-price {
  font-size: x-large!important;
  font: bolder!important;
  color: green;
}

.scroll {
  overflow-y: scroll;
  height: 60vh;
}

.sub-header {
  font-size: small;
  font-style: italic;
}
</style>
